<template>
  <div class="flex-grow-1 pb-12">
    <AdminTitle title="Packages">
       <template v-slot:actions>
        <div class="d-flex gap-12 align-center">
          <v-btn
            @click="getPackages()"
            :loading="status.getting"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </template>
    </AdminTitle>

    <div>
      <preloader v-if="status.getting" message="Please wait..." />

      <v-card v-if="!status.getting" class="shadow-lg">
        <v-card-text>
          <v-simple-table v-if="prices.length" dense>
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
                <th class="text-center">Subscribers</th>
                <th class="text-center">Active</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="price in refinedPrices" :key="price.id">
                <td class="text-capitalize">{{ price.metadata.title }}</td>
                <td>
                  ${{ price.unit_amount | amountNoneDecimal }} <span class="text-uppercase">{{ price.currency }}</span> / {{ price.interval_count }} {{ pluralize(price.interval, price.interval_count) }}
                </td>
                <td class="text-center">{{ $store.getters['subscription/getSubscriberCount'](price) }}</td>
                <td class="text-center">
                  <v-icon :color="price.active ? 'success' : 'default'" small>{{ price.active ? 'mdi-check' : 'mdi-close' }}</v-icon>
                </td>
                <td class="text-right">
                  <v-btn @click="showSettingsDialog(price)" icon small>
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <!-- SETTINGS -->
      <v-dialog
        v-if="Object.keys(data).length"
        v-model="settingsDialog"
        :max-width="$dialog.small"
        no-click-animation
        persistent
      >
        <v-card>
          <v-btn @click="hideSettingsDialog()" icon absolute right top>
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-card-title class="font-weight-black text-h5 primary--text">
            Update Package
          </v-card-title>    
          <v-card-text class="pt-3">
            <v-form ref="priceForm" @submit.prevent="validateForm()">
              <v-text-field
                v-model="data.metadata.title"
                :rules="[rules.required]"
                label="Title"
                outlined
              ></v-text-field>
              
              <v-textarea
                v-model="data.metadata.description"
                :rules="[rules.required]"
                label="Description"
                outlined
              ></v-textarea>
              
              <v-text-field
                v-model.number="data.metadata.order"
                class="text-capitalize"
                :rules="[rules.required]"
                label="Order"
                type="number"
                outlined
              ></v-text-field>

              <v-switch class="mt-0" label="Active" v-model="data.active"></v-switch>
              
              <div>
                <v-btn
                 @click="validateForm()" 
                 :loading="status.updating"
                 color="accent gradient"
                >Update</v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pluralize from 'pluralize'
import _orderBy from 'lodash/orderBy'
import rules from '@/rules'

export default {
  metaInfo: {
    title: 'Packages'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      pluralize,
      show: false,
      settingsDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      prices: state => state.subscription.packages,
      data: state => state.subscription.data,
      status: state => state.subscription.status,
    }),

    refinedPrices() {
      return _orderBy(this.prices, (price) => {
        return parseInt(price.metadata.order)
      }, 'asc')
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('pricing', [
      'updateStatus'
    ]),

    ...mapActions('subscription', ['getCustomers', 'getPackages', 'updatePrice']),

    showSettingsDialog(price) {
      this.$store.commit('subscription/setData', price)
      this.settingsDialog = true
    },
    
    hideSettingsDialog() {
      this.settingsDialog = false
    },
    
    validateForm() {
      if (this.$refs.priceForm.validate()) {
        Promise.resolve(this.updatePrice())
        .then(() => {
          this.hideSettingsDialog()
        })
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getPackages()
    this.getCustomers()
  }
}
</script>